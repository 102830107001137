import {QueryClient} from "@tanstack/react-query";
import AxiosApi from "./ApiService";
import {IQueryKey} from "../Models/CommonModels";
import {persistQueryClient, removeOldestQuery} from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import {QUERY_KEY_GLOBAL_DATA, REACT_QUERY_OFFLINE_CACHE} from "./Constants/QueryKeys";


const defaultQueryFunc: any = async ({queryKey}:IQueryKey) => {
   const { data } = await AxiosApi.get(`${queryKey[0]}`);
   return data
}

const AppQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            //refetchOnWindowFocus: false, retryOnMount:false, refetchOnReconnect: false, retry: 1, staleTime: 5 * 1000
            queryFn:defaultQueryFunc
        }}
});
AppQueryClient.setQueryData([QUERY_KEY_GLOBAL_DATA], [{id:1, title:'Srini'}, {id:2, title:'Y'}] );

/*
persistQueryClient({
    queryClient:AppQueryClient,
    persister: createSyncStoragePersister({
        storage: window.localStorage,
        retry: removeOldestQuery,
        key: REACT_QUERY_OFFLINE_CACHE,
    }),
    maxAge: Infinity
});
*/


export default AppQueryClient;


