import axios from "axios";
import {
    ACCESS_TOKEN_KEY,
    API_URL_AUTH_LOGIN,
    API_URL_AUTH_REFRESH,
    BASE_API_URL,
    GLOBAL_CONFIG,
    REFRESH_TOKEN_KEY,
    TENANT,
} from "./Constants/Common";
import { memoizedRefreshToken } from "./AuthFlow";

const AxiosApi = axios.create({
    baseURL: GLOBAL_CONFIG[BASE_API_URL],
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        tenant: TENANT,
    },
    withCredentials: true,
});
AxiosApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error)
);
AxiosApi.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { config, response } = error;
        if (response === undefined) return Promise.reject(error);
        const { status }: { status: number } = response;

        if ([API_URL_AUTH_LOGIN, API_URL_AUTH_REFRESH].includes(config.url)) return error;

        if (![API_URL_AUTH_LOGIN, API_URL_AUTH_REFRESH].includes(config.url) && status === 401) {
            const token = localStorage.getItem(ACCESS_TOKEN_KEY);
            const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
            if (token && refreshToken) await memoizedRefreshToken(token, refreshToken);
            if (!refreshToken) window.location.replace("/logout");
            return AxiosApi(config);
        }

        // If request method is GET, raise error will be handled by ErrorPage component
        if (error.response.config.method === "get")
            throw new Response("", { status: error.response?.status, statusText: error.message });
        return Promise.reject(error);
    }
);

export default AxiosApi;
