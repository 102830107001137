import { LoadingOutlined } from "@ant-design/icons";
import { Button, Result, Spin } from "antd";
import React from "react";
import { useRouteError, Link } from "react-router-dom";

const ErrorPage: React.FC = () => {
    const { statusText, message, status }: any = useRouteError();

    const chunkId = message ? message.match(/Loading chunk (\d+) failed/)?.[1] : undefined;
    const sessionStorageKey = `retry-${chunkId}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionStorageKey) || "false");

    const retryPageLoading = () => {
        if (!hasRefreshed) {
            window.sessionStorage.setItem(sessionStorageKey, "true");
            return window.location.reload();
        }
    };

    if (chunkId && !hasRefreshed) {
        retryPageLoading();
    } else {
        return (
            <>
                {statusText && status ? (
                    <Result
                        status={status >= 500 ? 500 : 404}
                        title={status}
                        subTitle={statusText}
                        extra={
                            <Link to="/">
                                <Button type="primary">Back Home</Button>
                            </Link>
                        }
                    />
                ) : (
                    <Result
                        status="error"
                        title="Oops!"
                        subTitle={
                            <>
                                <div>Sorry, an unexpected error has occurred.</div>
                                <p>
                                    <i>{statusText || message}</i>
                                </p>
                            </>
                        }
                        extra={
                            <Link to="/">
                                <Button type="primary">Back Home</Button>
                            </Link>
                        }
                    />
                )}
            </>
        );
    }

    // Show loading spinner on first render
    return (
        <div className="justify-content-center align-items-center d-flex flex-column h-100 min-vh-100 ">
            <Spin
                indicator={
                    <LoadingOutlined
                        onPointerOverCapture={undefined}
                        onPointerMoveCapture={undefined}
                        style={{ fontSize: 48 }}
                        spin
                    />
                }
            />
        </div>
    );
};

export default ErrorPage;
