import { IBaseResponse, ITokenResponse, IUser } from "../Models/CommonModels";
import {
    ACCESS_TOKEN_KEY,
    API_URL_AUTH_FORGOT_PASSWORD,
    API_URL_AUTH_LOGIN,
    API_URL_AUTH_PROFILE,
    API_URL_AUTH_REFRESH,
    API_URL_AUTH_RESET_PASSWORD,
    PROFILE_KEY,
    REFRESH_TOKEN_EXPIRY_TIME_KEY,
    REFRESH_TOKEN_KEY,
} from "./Constants/Common";
import AxiosApi from "./ApiService";
import memoize from "memoizee";

export const getAccessToken = async (email: string, password: string) => {
    const response = await AxiosApi.post<ITokenResponse>(API_URL_AUTH_LOGIN, {
        email,
        password,
    });
    return response.data;
};

export const refreshAccessToken = async (token: string, refreshToken: string) => {
    const response = await AxiosApi.post<ITokenResponse>(API_URL_AUTH_REFRESH, {
        token,
        refreshToken,
    });

    if (response.status !== 200) window.location.replace("/logout");
    if (response.status === 200) {
        localStorage.setItem(ACCESS_TOKEN_KEY, response.data.token);
        localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);
        localStorage.setItem(REFRESH_TOKEN_EXPIRY_TIME_KEY, response.data.refreshTokenExpiryTime);
    }

    return response.data;
};

const maxAge = (1000 * 60) / 2;
export const memoizedRefreshToken = memoize(refreshAccessToken, { maxAge });

export const forgotUserPassword = async (email: string) => {
    const response = await AxiosApi.post<IBaseResponse>(API_URL_AUTH_FORGOT_PASSWORD, { email });
    return response;
};

export const resetUserPassword = async (email: string, password: string, token: string) => {
    const response = await AxiosApi.post<IBaseResponse>(API_URL_AUTH_RESET_PASSWORD, {
        email,
        password,
        token,
    });
    return response;
};

export const getProfile = async () => {
    const response = await AxiosApi.get<IUser>(API_URL_AUTH_PROFILE);
    return response;
};

export const logoutLocalStorage = async () => {
    localStorage.removeItem(PROFILE_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_EXPIRY_TIME_KEY);
};
