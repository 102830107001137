import { route, number, string, boolean } from "react-router-typesafe-routes/dom";

const Group = "group";
const Patient = "patient";
const Order = "order";
const Contact = "contact";

const Onboarding = route("onboarding");

export const AppRouters = {
    Root: route(""),
    Any: route("*"),
    Login: route("login"),
    Logout: route("logout"),
    ForgotPassword: route("forgot-password"),
    ResetPassword: route("reset-password"),
    Group: route(
        Group,
        {},
        {
            GroupId: route(
                ":id",
                {
                    params: { id: string().defined() },
                },
                {
                    GroupOnboarding: route("onboarding"),
                    Profile: route("profile-page"),
                    EligibilityImportOverview: route("eligibility-import-overview"),
                    Aggregator: route(
                        "aggregator/:fromRoute?",
                        {
                            params: { fromRoute: string() },
                        },
                        { Onboarding }
                    ),
                    ManageAggregators: route(
                        "manage-aggregators/:aggregatorId/type/:typeId/:fromRoute?",
                        {
                            params: {
                                aggregatorId: string().defined(),
                                typeId: number().defined(),
                                fromRoute: string().defined(),
                            },
                        },
                        { Onboarding }
                    ),
                    CompanyPlan: route("company-plan-information", {}, { Onboarding }),
                    Identification: route("identification-page", {}, { Onboarding }),
                    EligibilityImportInformation: route(
                        "eligibility-import-information-page",
                        {},
                        { Onboarding }
                    ),
                    FormularyInformation: route("formulary-information-page", {}, { Onboarding }),
                    AdditionalNotes: route("additional-notes-page", {}, { Onboarding }),
                    ManageGroupContact: route(
                        "manage-contacts",
                        { searchParams: { fromEligibilityInfo: boolean() } },
                        { Onboarding }
                    ),
                }
            ),
        }
    ),
    Patient: route(
        Patient,
        {},
        {
            PatientId: route(
                ":id",
                {
                    params: { id: string().defined() },
                },
                {
                    PatientOnboarding: route("onboarding"),
                    Allergies: route("allergies", {}, { Onboarding }),
                    Allergy: route("allergy", {}, { Onboarding }),
                    Medications: route("medications", {}, { Onboarding }),
                    Medication: route("medication", {}, { Onboarding }),
                    AdditionalContact: route("additional-contact", {}, { Onboarding }),
                    AdditionalPhone: route("additional-phone", {}, { Onboarding }),
                    AdditionalEmail: route("additional-email", {}, { Onboarding }),
                    AdditionalNotes: route("additional-notes-page", {}, { Onboarding }),
                    AssignGroup: route("assign-group", {}, { Onboarding }),
                    Profile: route("profile"),
                    SubscriberAssign: route("assign-subscriber"),
                    SubscriberCreate: route("create-subscriber"),
                    SubscriberUpdate: route("update-subscriber"),
                    PlanInformation: route("plan-information", {}, { Onboarding }),
                }
            ),
        }
    ),
    Order: route(
        Order,
        {},
        {
            PatientLookup: route("patient-lookup-page"),
            PatientInformation: route(":orderId/patient-information", {
                params: { orderId: string().defined() },
                searchParams: { onCancel: string(), isEdit: boolean() },
            }),
            PatientInformationNoOrder: route("patient-information/:patientId", {
                params: { patientId: string().defined() },
            }),
            Prescriber: route(":orderId/prescriber-information-page", {
                params: { orderId: string().defined() },
            }),
            Medication: route(":orderId/medication-information-page", {
                params: { orderId: string().defined() },
            }),
            NewPrescriber: route(":orderId/create-prescriber", {
                params: { orderId: string().defined() },
            }),
            Summary: route("view-order-summary/:orderId?", {
                params: { orderId: string() },
                searchParams: { activeTab: string() },
            }),
        }
    ),
    CONTACT: route(
        Contact,
        {},
        {
            CreateContact: route(
                "new",
                { searchParams: { redirect: string(), hasContacts: boolean() } },
                { Onboarding }
            ),
            CreateAggregatorContact: route(
                "new/:aggregatorId",
                {
                    params: { aggregatorId: string().defined() },
                    searchParams: { redirect: string(), hasContacts: boolean() },
                },
                { Onboarding }
            ),
        }
    ),
};
