import { Spin } from "antd";
import { useEffect } from "react";
import { useAuth } from "../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { BaseProps } from "../Models/CommonModels";
import { LoadingOutlined } from "@ant-design/icons";

const PrivateRoute = ({ children }: BaseProps) => {
    // Router
    const navigate = useNavigate();

    const auth = useAuth();

    useEffect(() => {
        if (auth?.token) navigate("/");
    }, [auth?.token]);

    return (
        <>
            {!auth?.token && !auth?.user ? (
                children
            ) : (
                <div className="justify-content-center align-items-center d-flex flex-column h-100 min-vh-100 ">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{ fontSize: 48 }}
                                spin
                                onPointerOverCapture={undefined}
                                onPointerMoveCapture={undefined}
                            />
                        }
                    />
                </div>
            )}
        </>
    );
};
export default PrivateRoute;
