import { theme } from "antd";

//https://ant.design/docs/react/migrate-less-variables
const AppTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: "#1a75ad",
        colorInfo: "#1a75ad",
    },
    components: {
        Result: {
            subtitleFontSize: 16,
        },
    },
};
export default AppTheme;
