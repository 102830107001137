import { Spin } from "antd";
import { ReactNode } from "react";
import { useAuth } from "../Hooks/useAuth";
import { Navigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { EXTERNAL, ElectRxUsers } from "../Utilities/Constants/UserRoles";
import { AppRouters } from "../Utilities/Routers";

const PrivateRoute = ({
    children,
    roles = ElectRxUsers,
}: {
    children: ReactNode;
    roles?: string[];
}) => {
    const auth = useAuth();

    if (!auth?.token) {
        return <Navigate to={AppRouters.Login.path} />;
    }

    const isAllowed = roles.includes(EXTERNAL);
    const isExternal = auth?.hasRoles([EXTERNAL]);
    if (!isAllowed && isExternal) {
        return <Navigate to={AppRouters.Order.PatientLookup.path} replace />;
    }

    return (
        <>
            {auth?.token && auth?.user ? (
                children
            ) : (
                <div className="justify-content-center align-items-center d-flex flex-column h-100 min-vh-100 ">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{ fontSize: 48 }}
                                spin
                                onPointerOverCapture={undefined}
                                onPointerMoveCapture={undefined}
                            />
                        }
                    />
                </div>
            )}
        </>
    );
};
export default PrivateRoute;
