import { AppRouters } from "../Routers";
import MainLayout from "../../Components/Layout/MainLayout";
import ErrorPage from "../../Components/Layout/ErrorPage";
import PrivateRoute from "../../Components/PrivateRoute";

export const GroupRoute = {
    path: AppRouters.Group.path,
    element: (
        <PrivateRoute>
            <MainLayout />
        </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
        {
            index: true,
            async lazy() {
                const { GroupCreatePage } = await import(
                    "../../Pages/Group/ContactInformation/GroupCreatePage"
                );
                return { Component: GroupCreatePage };
            },
        },
        {
            path: AppRouters.Group.GroupId.path,
            async lazy() {
                const { GroupPage } = await import(
                    "../../Pages/Group/ContactInformation/GroupPage"
                );
                return { Component: GroupPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.GroupOnboarding.path,
            async lazy() {
                const { GroupOnboardingPage } = await import(
                    "../../Pages/Group/ContactInformation/GroupOnboardingPage"
                );
                return { Component: GroupOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.Profile.path,
            async lazy() {
                const { GroupProfilePage } = await import("../../Pages/Group/GroupProfilePage");
                return { Component: GroupProfilePage };
            },
        },
        {
            path: AppRouters.Group.GroupId.Aggregator.path,
            async lazy() {
                const { AggregatorPage } = await import(
                    "../../Pages/Group/Aggregator/AggregatorPage"
                );
                return { Component: AggregatorPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.Aggregator.Onboarding.path,
            async lazy() {
                const { AggregatorOnboardingPage } = await import(
                    "../../Pages/Group/Aggregator/AggregatorOnboardingPage"
                );
                return { Component: AggregatorOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.ManageGroupContact.path,
            async lazy() {
                const { ManageGroupContactPage } = await import(
                    "../../Pages/Group/Contacts/ManageGroupContactPage"
                );
                return { Component: ManageGroupContactPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.ManageGroupContact.Onboarding.path,
            async lazy() {
                const { ManageGroupContactOnboardingPage } = await import(
                    "../../Pages/Group/Contacts/ManageGroupContactOnboardingPage"
                );
                return { Component: ManageGroupContactOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.ManageAggregators.path,
            async lazy() {
                const { ManageAggregatorsPage } = await import(
                    "../../Pages/Group/Aggregator/ManageAggregatorsPage"
                );
                return { Component: ManageAggregatorsPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.ManageAggregators.Onboarding.path,
            async lazy() {
                const { ManageAggregatorsOnboardingPage } = await import(
                    "../../Pages/Group/Aggregator/ManageAggregatorsOnboardingPage"
                );
                return { Component: ManageAggregatorsOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.CompanyPlan.path,
            async lazy() {
                const { CompanyPlanPage } = await import(
                    "../../Pages/Group/CompanyPlan/CompanyPlanPage"
                );
                return { Component: CompanyPlanPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.CompanyPlan.Onboarding.path,
            async lazy() {
                const { CompanyPlanOnboardingPage } = await import(
                    "../../Pages/Group/CompanyPlan/CompanyPlanOnboardingPage"
                );
                return { Component: CompanyPlanOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.Identification.path,
            async lazy() {
                const { IdentificationPage } = await import(
                    "../../Pages/Group/Identification/IdentificationPage"
                );
                return { Component: IdentificationPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.Identification.Onboarding.path,
            async lazy() {
                const { IdentificationOnboardingPage } = await import(
                    "../../Pages/Group/Identification/IdentificationOnboardingPage"
                );
                return { Component: IdentificationOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.EligibilityImportInformation.path,
            async lazy() {
                const { EligibilityImportInformationPage } = await import(
                    "../../Pages/Group/Eligibility/EligibilityImportInformationPage"
                );
                return { Component: EligibilityImportInformationPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.EligibilityImportInformation.Onboarding.path,
            async lazy() {
                const { EligibilityImportInformationOnboardingPage } = await import(
                    "../../Pages/Group/Eligibility/EligibilityImportInformationOnboardingPage"
                );
                return { Component: EligibilityImportInformationOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.EligibilityImportOverview.path,
            async lazy() {
                const { EligibilityImportOverview } = await import(
                    "../../Pages/Group/EligibilityImportOverview"
                );
                return { Component: EligibilityImportOverview };
            },
        },
        {
            path: AppRouters.Group.GroupId.FormularyInformation.path,
            async lazy() {
                const { FormularyInformationPage } = await import(
                    "../../Pages/Group/FormularyInformation/FormularyInformationPage"
                );
                return { Component: FormularyInformationPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.FormularyInformation.Onboarding.path,
            async lazy() {
                const { FormularyInformationOnboardingPage } = await import(
                    "../../Pages/Group/FormularyInformation/FormularyInformationOnboardingPage"
                );
                return { Component: FormularyInformationOnboardingPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.AdditionalNotes.path,
            async lazy() {
                const { AdditionalNotesPage } = await import(
                    "../../Pages/Group/Notes/AdditionalNotesPage"
                );
                return { Component: AdditionalNotesPage };
            },
        },
        {
            path: AppRouters.Group.GroupId.AdditionalNotes.Onboarding.path,
            async lazy() {
                const { AdditionalNotesOnboardingPage } = await import(
                    "../../Pages/Group/Notes/AdditionalNotesOnboardingPage"
                );
                return { Component: AdditionalNotesOnboardingPage };
            },
        },
    ],
};
