export const TENANT = "root";
export const BASE_API_URL = "BASE_API_URL";
export const API_VERSION = "/v1";
export const GLOBAL_CONFIG = window["globalConfig"];

export const QUERY_KEY_GLOBAL_DATA = "global-data";

export const API_URL_AUTH_LOGIN = "/tokens";
export const API_URL_AUTH_REFRESH = "/tokens/refresh";
export const API_URL_AUTH_PROFILE = "/users/profile";
export const API_URL_AUTH_FORGOT_PASSWORD = "/users/forgot-password";
export const API_URL_AUTH_RESET_PASSWORD = "/users/reset-password";

// Tokens
export const PROFILE_KEY = "userInfo";
export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const REFRESH_TOKEN_EXPIRY_TIME_KEY = "refreshTokenExpiryTime";

// Reset Password
export const RESET_TOKEN_KEY = "token";
export const EMAIL_ADDRESS_KEY = "email";

//
export const DEBOUNCE_KEY: number = 500;
