import { utcToZonedTime } from "date-fns-tz";
import { IOption, IUiOption } from "../Models/CommonModels";
import { format, parseISO } from "date-fns";
import { Address } from "../Models/Queries/Shared/Contact";
import { IPatientContactInformationResponse } from "../Models/Queries/Patients/ContactInformation";
import { isStringAndEmpty } from "./Extensions";
import dayjs from "dayjs";
import { AddressType, ContactType, IContact } from "../Models/Queries/Shared/Contact";

declare global {
    interface Array<T> {
        ToUiOptions(): Array<IUiOption>;
        StringsToUiOptions(): Array<IUiOption>;
    }
    interface String {
        strFormat(placeholders: Record<string, any>): string;
        strFormatUrl(placeholders: Record<string, any>): string;
    }
}

Array.prototype.ToUiOptions = function (): Array<IUiOption> {
    return this.map((option: IOption) => ({
        value: option.value,
        label: option.name,
    }));
};

// Array.prototype.StringsToUiOptions = function (): Array<IUiOption> {
//     return this.map((option: string) => ({
//         value: option /*this can be number for enum*/,
//         label: option,
//     }));
// };

String.prototype.strFormat = function (placeholders: Record<string, any>): string {
    let initialValue = this;
    for (const [key, value] of Object.entries(placeholders)) {
        initialValue = initialValue.replace(new RegExp("\\{" + key + "\\}", "gi"), value);
    }
    return `${initialValue}`;
};

String.prototype.strFormatUrl = function (placeholders: Record<string, any>): string {
    let initialValue = this;
    for (const [key, value] of Object.entries(placeholders)) {
        initialValue = initialValue.replace(`:${key}`, value);
    }
    return `${initialValue}`;
};

export const toLocaleDateTimeString = (utcDate: Date) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userLocalDate = utcToZonedTime(utcDate, userTimeZone);

    return format(userLocalDate, "yyyy-MM-dd HH:mm");
};

export const toLocaleDateString = (utcDate: Date) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userLocalDate = utcToZonedTime(utcDate, userTimeZone);
    return format(userLocalDate, "MM/dd/yyyy");
};

// NOTE: When selecting date in date picker if the date existed before, the new date uses the same time and timezone.
// When no date before (or erased), then the date is selected in local time and then converted to utc by datepicker.
// We want to send dates in UTC only.
// makeDateUtc makes the actually selected date utc. Which makes the dates selected in UTC.
// Datepicker does not provide UTC DatePicker option. 
export const tryNormalizeDateTimeToUTC = (date: string | null) => {
    if (date === null) return null;

    var curr = new Date();
    var timezoneOffsetInHours = curr.getTimezoneOffset() / 60;

    // If hours are 0s, then we are modifiying existing date with the right time, no need for modification.
    if (dayjs.utc(date).hour() !== 0) {
        if(timezoneOffsetInHours < 0){
            return dayjs(date).subtract(timezoneOffsetInHours, 'hour').toISOString();
        }else{
            return dayjs(date).add(timezoneOffsetInHours, 'hour').toISOString();
        }
    }

    return date;
}

export const normalizeDateTimeToUTC = (date: string) => {
    var curr = new Date();
    var timezoneOffsetInHours = curr.getTimezoneOffset() / 60;

    // If hours are 0s, then we are modifiying existing date with the right time, no need for modification.
    if (dayjs.utc(date).hour() !== 0) {
        if(timezoneOffsetInHours < 0){
            return dayjs(date).subtract(timezoneOffsetInHours, 'hour').toISOString();
        }else{
            return dayjs(date).add(timezoneOffsetInHours, 'hour').toISOString();
        }
    }

    return date;
}

export const toUtcDateString = (utcDate: Date) => {
    const month = String(utcDate.getUTCMonth() +  1).padStart(2, '0'); // Months are  0-indexed, so add  1
    const day = String(utcDate.getUTCDate()).padStart(2, '0');
    const year = utcDate.getUTCFullYear();
    return `${month}/${day}/${year}`;
};

export const stringToUtcDateString = (dateString: string) => {
    const date: Date = parseISO(dateString);
    const month = String(date.getUTCMonth() +  1).padStart(2, '0'); // Months are  0-indexed, so add  1
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
};

export const getValueOrDefault = (value: any | null | undefined) => {
    return value === null || value === undefined || isStringAndEmpty(value) ? "N/A" : value;
};

export const getStringFromBoolean = (value: any | null | undefined) => {
    return value === null || value === undefined ? null : value ? "Yes" : "No";
};

export const getFullName = (value: ContactType | IContact | IPatientContactInformationResponse | undefined | null) => {
    if (value === undefined || value === null)
        return "";
    
    if (Object.keys(value).includes("middleName"))
    {
        let cast = value as IPatientContactInformationResponse;
        return (cast?.firstName ?? "") + " " + (cast?.middleName ?? "") + " " + (cast?.lastName ?? "")
    }
    else
    {
        let cast = value as IContact;
        return [cast.firstName, cast.middleInitial, cast.lastName].filter(Boolean).join(" ");
    }
};

export const formatFullName = (firstName: string, lastName: string, middleInitial?: string) => {
    if (middleInitial)
    {
        return (firstName ?? "") + " " + (middleInitial ?? "") + ". " + (lastName ?? "")
    }
    else
    {
        return [firstName, lastName].filter(Boolean).join(" ");
    }
};

export const getFullAddress = (value: AddressType | Address | undefined) => {
    if (
        value === undefined ||
        [value.addressLine1, value.localityCity, value.regionState, value.postalCode].includes(null)
    )
        return null;
    return `${value?.addressLine1}, ${value?.localityCity} ${value?.regionState} ${value?.postalCode}`;
};

export const getNameFromEnumOptions = (value: Number | null | undefined, enumOptions: Array<IUiOption> | null | undefined) =>
{
    if (value === null || value === undefined || !enumOptions) return "N/A";
    return enumOptions.find((i) => i.value === value)?.label?.toString() ?? "N/A";
}

// Convert Base64 to File
export const base64ToFile = (base64String: string, contentType: string, fileName: string) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return new File([blob], fileName, { type: blob.type });
};

export const blobToUploadFile = (blob: File) => {
    const uniqueId = Math.random().toString(36).substring(7);

    return {
        uid: uniqueId,
        name: blob.name, 
        status: "done",
        url: URL.createObjectURL(blob),
        size: blob.size,
        type: blob.type,
        originFileObj: blob, 
    };
};

export const removeKeysFromObject = (obj: any, keysToRemove: string[]) => {
    // Function to remove a list of keys from nested objects
    
    if (Array.isArray(obj)) {
        // If the object is an array, iterate through its elements
        for (let i = 0; i < obj.length; i++) {
            obj[i] = removeKeysFromObject(obj[i], keysToRemove);
        }
    } else if (typeof obj === "object" && obj !== null && obj !== undefined) {
        // If the object is an object, iterate through properties
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (keysToRemove.includes(key)) {
                    delete obj[key];
                } else {
                    obj[key] = removeKeysFromObject(obj[key], keysToRemove);
                }
            }
        }
    }
    return obj;
};

export const preserveSearchParams = (url: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    return `${url}?${searchParams.toString()}`;
};
