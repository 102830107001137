import { AppRouters } from "../Routers";
import MainLayout from "../../Components/Layout/MainLayout";
import ErrorPage from "../../Components/Layout/ErrorPage";
import PrivateRoute from "../../Components/PrivateRoute";

export const PatientRoute = {
    path: AppRouters.Patient.path,
    element: (
        <PrivateRoute>
            <MainLayout />
        </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
        {
            index: true,
            async lazy() {
                const { PatientCreatePage } = await import(
                    "../../Pages/Patient/ContactInformation/PatientCreatePage"
                );
                return { Component: PatientCreatePage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.path,
            async lazy() {
                const { PatientPage } = await import(
                    "../../Pages/Patient/ContactInformation/PatientPage"
                );
                return { Component: PatientPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.PatientOnboarding.path,
            async lazy() {
                const { PatientOnboardingPage } = await import(
                    "../../Pages/Patient/ContactInformation/PatientOnboardingPage"
                );
                return { Component: PatientOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Profile.path,
            async lazy() {
                const { PatientProfilePage } = await import(
                    "../../Pages/Patient/PatientProfilePage"
                );
                return { Component: PatientProfilePage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Allergies.path,
            async lazy() {
                const { AllergiesPage } = await import(
                    "../../Pages/Patient/Allergies/AllergiesPage"
                );
                return { Component: AllergiesPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Allergies.Onboarding.path,
            async lazy() {
                const { AllergiesOnboardingPage } = await import(
                    "../../Pages/Patient/Allergies/AllergiesOnboardingPage"
                );
                return { Component: AllergiesOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Allergy.path,
            async lazy() {
                const { AllergyPage } = await import("../../Pages/Patient/Allergy/AllergyPage");
                return { Component: AllergyPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Allergy.Onboarding.path,
            async lazy() {
                const { AllergyOnboardingPage } = await import(
                    "../../Pages/Patient/Allergy/AllergyOnboardingPage"
                );
                return { Component: AllergyOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Medications.path,
            async lazy() {
                const { MedicationsPage } = await import(
                    "../../Pages/Patient/Medications/MedicationsPage"
                );
                return { Component: MedicationsPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Medications.Onboarding.path,
            async lazy() {
                const { MedicationsOnboardingPage } = await import(
                    "../../Pages/Patient/Medications/MedicationsOnboardingPage"
                );
                return { Component: MedicationsOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Medication.path,
            async lazy() {
                const { MedicationPage } = await import(
                    "../../Pages/Patient/Medication/MedicationPage"
                );
                return { Component: MedicationPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.Medication.Onboarding.path,
            async lazy() {
                const { MedicationOnboardingPage } = await import(
                    "../../Pages/Patient/Medication/MedicationOnboardingPage"
                );
                return { Component: MedicationOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalContact.path,
            async lazy() {
                const { AdditionalContactPage } = await import(
                    "../../Pages/Patient/AdditionalContact/AdditionalContactPage"
                );
                return { Component: AdditionalContactPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalContact.Onboarding.path,
            async lazy() {
                const { AdditionalContactOnboardingPage } = await import(
                    "../../Pages/Patient/AdditionalContact/AdditionalContactOnboardingPage"
                );
                return { Component: AdditionalContactOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalPhone.path,
            async lazy() {
                const { AdditionalPhonePage } = await import(
                    "../../Pages/Patient/AdditionalContact/AdditionalPhonePage"
                );
                return { Component: AdditionalPhonePage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalPhone.Onboarding.path,
            async lazy() {
                const { AdditionalPhoneOnboardingPage } = await import(
                    "../../Pages/Patient/AdditionalContact/AdditionalPhoneOnboardingPage"
                );
                return { Component: AdditionalPhoneOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalEmail.path,
            async lazy() {
                const { AdditionalEmailPage } = await import(
                    "../../Pages/Patient/AdditionalContact/AdditionalEmailPage"
                );
                return { Component: AdditionalEmailPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalEmail.Onboarding.path,
            async lazy() {
                const { AdditionalEmailOnboardingPage } = await import(
                    "../../Pages/Patient/AdditionalContact/AdditionalEmailOnboardingPage"
                );
                return { Component: AdditionalEmailOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalNotes.path,
            async lazy() {
                const { AdditionalNotesPage } = await import(
                    "../../Pages/Patient/Notes/AdditionalNotesPage"
                );
                return { Component: AdditionalNotesPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AdditionalNotes.Onboarding.path,
            async lazy() {
                const { AdditionalNotesOnboardingPage } = await import(
                    "../../Pages/Patient/Notes/AdditionalNotesOnboardingPage"
                );
                return { Component: AdditionalNotesOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AssignGroup.path,
            async lazy() {
                const { AssignGroupPage } = await import(
                    "../../Pages/Patient/AssignGroup/AssignGroupPage"
                );
                return { Component: AssignGroupPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.AssignGroup.Onboarding.path,
            async lazy() {
                const { AssignGroupOnboardingPage } = await import(
                    "../../Pages/Patient/AssignGroup/AssignGroupOnboardingPage"
                );
                return { Component: AssignGroupOnboardingPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.SubscriberAssign.path,
            async lazy() {
                const { SubscriberAssignPage } = await import(
                    "../../Pages/Patient/Subscriber/SubscriberAssignPage"
                );
                return { Component: SubscriberAssignPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.SubscriberCreate.path,
            async lazy() {
                const { SubscriberCreatePage } = await import(
                    "../../Pages/Patient/Subscriber/SubscriberCreatePage"
                );
                return { Component: SubscriberCreatePage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.SubscriberUpdate.path,
            async lazy() {
                const { SubscriberUpdatePage } = await import(
                    "../../Pages/Patient/Subscriber/SubscriberUpdatePage"
                );
                return { Component: SubscriberUpdatePage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.PlanInformation.path,
            async lazy() {
                const { PlanInformationPage } = await import(
                    "../../Pages/Patient/Subscriber/PlanInformationPage"
                );
                return { Component: PlanInformationPage };
            },
        },
        {
            path: AppRouters.Patient.PatientId.PlanInformation.Onboarding.path,
            async lazy() {
                const { PlanInformationOnboardingPage } = await import(
                    "../../Pages/Patient/Subscriber/PlanInformationOnboardingPage"
                );
                return { Component: PlanInformationOnboardingPage };
            },
        },
    ],
};
