import { AppRouters } from "../Routers";
import MainLayout from "../../Components/Layout/MainLayout";
import ErrorPage from "../../Components/Layout/ErrorPage";
import PrivateRoute from "../../Components/PrivateRoute";

export const ContactRoute = {
    path: AppRouters.CONTACT.path,
    element: (
        <PrivateRoute>
            <MainLayout />
        </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
        {
            path: AppRouters.CONTACT.CreateContact.path,
            async lazy() {
                const { ContactCreatePage } = await import(
                    "../../Pages/Group/Contacts/CreateContactPage"
                );
                return { Component: ContactCreatePage };
            },
        },
        {
            path: AppRouters.CONTACT.CreateContact.Onboarding.path,
            async lazy() {
                const { CreateContactOnboardingPage } = await import(
                    "../../Pages/Group/Contacts/CreateContactOnboardingPage"
                );
                return { Component: CreateContactOnboardingPage };
            },
        },
        {
            path: AppRouters.CONTACT.CreateAggregatorContact.path,
            async lazy() {
                const { CreateAggregatorContactPage } = await import(
                    "../../Pages/Group/Contacts/CreateAggregatorContactPage"
                );
                return { Component: CreateAggregatorContactPage };
            },
        },
        {
            path: AppRouters.CONTACT.CreateAggregatorContact.Onboarding.path,
            async lazy() {
                const { CreateAggregatorContactOnboardingPage } = await import(
                    "../../Pages/Group/Contacts/CreateAggregatorContactOnboardingPage"
                );
                return { Component: CreateAggregatorContactOnboardingPage };
            },
        },
    ],
};
