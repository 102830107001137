import React from "react";
import { AppRouters } from "../../Utilities/Routers";
import { Link } from "react-router-dom";

const HeaderLogo: React.FC = () => {
    return (
        <Link to={AppRouters.Root.path}>
            <div className="header-logo">
                <img src="/assets/icons/web-title-logo.svg" alt="ElectRx" />
            </div>
        </Link>
    );
};

export default HeaderLogo;
