import React, { useState } from "react";
import { Layout, Button, theme } from "antd";
import { Outlet } from "react-router-dom";
import { IDefaultOutletContext } from "../../Models/CommonModels";
import MainSideMenu from "./MainSideMenu";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useAuth } from "../../Hooks/useAuth";
import HeaderLogo from "./HeaderLogo";
import UserProfileCom from "./UserProfileCom";
import { EXTERNAL } from "../../Utilities/Constants/UserRoles";

const { Header, Sider, Content } = Layout;

const MainLayout: React.FC = () => {
    const auth = useAuth();
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const isExternal = auth?.hasRoles([EXTERNAL]);

    const outletContext: IDefaultOutletContext = {
        header: "ElectRx",
        isExternal,
    };

    return (
        <Layout>
            <Header className="d-flex justify-content-between align-items-center main-header">
                <HeaderLogo />
                <UserProfileCom user={auth.user} isExternal={isExternal} />
            </Header>
            <Layout>
                {!isExternal && (
                    <Sider
                        trigger={null}
                        collapsible
                        collapsed={collapsed}
                        breakpoint="md"
                        className="main-side-menu shadow-sm"
                        style={{ background: colorBgContainer }}
                    >
                        <div className="d-flex flex-column h-100">
                            <MainSideMenu />
                            <Button
                                type="text"
                                icon={
                                    collapsed ? (
                                        <MenuUnfoldOutlined
                                            onPointerOverCapture={undefined}
                                            onPointerMoveCapture={undefined}
                                        />
                                    ) : (
                                        <MenuFoldOutlined
                                            onPointerOverCapture={undefined}
                                            onPointerMoveCapture={undefined}
                                        />
                                    )
                                }
                                onClick={() => setCollapsed(!collapsed)}
                                className={
                                    collapsed
                                        ? "btn-toggle p-2"
                                        : "btn-toggle align-self-start mx-4 p-2"
                                }
                            />
                            {collapsed && (
                                <div className="switcher" onClick={() => setCollapsed(!collapsed)}>
                                    <img
                                        src="/assets/icons/sidemenu-fixed-switcher.svg"
                                        alt="expend"
                                    />
                                </div>
                            )}
                        </div>
                    </Sider>
                )}
                <Content className="main-container p-0 m-0">
                    <Outlet context={outletContext} />
                </Content>
            </Layout>
        </Layout>
    );
};
export default MainLayout;
