import { Menu } from "antd";
import {
    CheckCircleOutlined,
    DashboardOutlined,
    UnorderedListOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AppRouters } from "../../Utilities/Routers";

const MainSideMenu = () => {
    let navigate = useNavigate();
    return (
        <Menu
            mode="inline"
            className="flex-grow-1"
            defaultSelectedKeys={[""]}
            items={[
                {
                    key: "",
                    icon: (
                        <DashboardOutlined
                            onPointerOverCapture={undefined}
                            onPointerMoveCapture={undefined}
                        />
                    ),
                    label: "Dashboard",
                    children: [
                        {
                            key: AppRouters.Group.path,
                            label: "Create New Group",
                        },
                        {
                            key: AppRouters.Patient.path,
                            label: "Create New Patient",
                        },
                        {
                            key: AppRouters.Order.PatientLookup.path,
                            label: "Create New Order",
                        },
                    ],
                },
                {
                    key: AppRouters.Order.Summary.path,
                    icon: (
                        <UnorderedListOutlined
                            onPointerOverCapture={undefined}
                            onPointerMoveCapture={undefined}
                        />
                    ),
                    label: "Order Summary",
                },
                {
                    key: AppRouters.Group.GroupId.EligibilityImportOverview.path,
                    icon: (
                        <CheckCircleOutlined
                            onPointerOverCapture={undefined}
                            onPointerMoveCapture={undefined}
                        />
                    ),
                    label: "Eligibility Imports",
                },
                {
                    key: AppRouters.Logout.path,
                    icon: (
                        <UserOutlined
                            onPointerOverCapture={undefined}
                            onPointerMoveCapture={undefined}
                        />
                    ),
                    label: "Log Out",
                },
            ]}
            onClick={({ key }) => navigate(key)}
        />
    );
};

export default MainSideMenu;
