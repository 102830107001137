/*Object.prototype.isString = function isString() {
    return typeof this === 'string' || this instanceof String
};*/

import { format } from "date-fns";
import { isDayjs } from "dayjs";
import { jwtDecode } from "jwt-decode";
// import LocalTokenService from "../services/localTokenService";

export const isFunction = (source) => source instanceof Function;

export const isNotNullOrUndefined = (source) =>
    typeof source !== "undefined" && source !== null;

export const isObjectAndNotNull = (source) =>
    typeof source !== "undefined" &&
    source !== null &&
    typeof source === "object";

export const isString = (source) =>
    (typeof source !== "undefined" && typeof source === "string") ||
    source instanceof String;

export const isStringAndNotEmpty = (source) =>
    typeof source !== "undefined" &&
    (typeof source === "string" || source instanceof String) &&
    source.trim().length > 0;

export const isStringAndEmpty = (source) =>
    typeof source !== "undefined" &&
    (typeof source === "string" || source instanceof String) &&
    source.trim().length === 0;

export const formatDateTo = (date, formatStr) =>
    format(new Date(date), formatStr);

export const IsArrayIncludes = (arr, value) =>
    IsArrayWithLength(arr) ? arr.includes(value) : false;

export const IsKeyTrue = (dic, key) => dic && dic[key] === true;

export const AreEqualNumbers = (n1, n2) =>
    IsNotEmpty(n1) &&
    IsNotEmpty(n2) &&
    n1.replace("+", "") === n2.replace("+", "");

export const IsArrayWithLength = (items) =>
    Array.isArray(items) && items.length > 0;

export const IsNotEmpty = (value) =>
    typeof value !== "undefined" &&
    value != null &&
    typeof value === "string" &&
    value.trim().length > 0;

export const IsEmptyObject = (object) =>
    !(
        IsNotUndefinedOrNotNull(object) &&
        object.constructor === Object &&
        Object.keys(object).length > 0
    );

export const IsEmptyObjectValues = (object) => {
    for (var key in object) {
        if (
            object[key] !== null &&
            object[key] !== "" &&
            object[key] !== undefined
        )
            return false;
    }
    return true;
};

// Check if object has at least one non null/undefiend value
export const IsNotEmptyObjectValues = (object) => {
    if (object === undefined || Object.keys(object).length === 0) return true;
    return Object.values(object).every((v) =>
        v && typeof v === "object"
            ? IsNotEmptyObjectValues(v)
            : v === null || v === undefined || v === ""
    );
};

// Check if object has at least one null/undefiend value
export const hasEmptyObjectValues = (object) => {
    if (object === undefined || Object.keys(object).length === 0) return true;
    return Object.values(object).some((v) =>
        v && typeof v === "object"
            ? hasEmptyObjectValues(v)
            : v === null || v === undefined || v === ""
    );
};

export const IsNotUndefinedOrNotNull = (value) =>
    typeof value !== "undefined" && value != null;

export const IsFunction = (f) =>
    f !== undefined && f !== null && typeof f === "function";

export const IsNumberGreaterThanZero = (value) =>
    typeof value !== "undefined" && typeof value == "number" && value > 0;

export const ObjectKeysFilter = (source, keys) =>
    Object.keys(source)
        .filter((key) => keys.includes(key))
        .reduce((obj, key) => {
            obj[key] = source[key];
            return obj;
        }, {});

const ROLE = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export const tokenRoles = (token) => {
    try {
        const jwt = isNotNullOrUndefined(token) ? jwtDecode(token) : undefined;
        if (isNotNullOrUndefined(jwt)) return jwt[ROLE] || [];
    } catch {
        return [];
    }
};

// export const UserHasRoles = (roles, token) => {
//     try {
//         if (token === undefined) token = LocalTokenService.getToken().token;
//         const tRoles = tokenRoles(token);
//         return (
//             IsArrayWithLength(tRoles) &&
//             tRoles.find((r) => roles.includes(r)) !== undefined
//         );
//     } catch {
//         return false;
//     }
// };

// export const CurrentUserHasRoles = (roles) => {
//     try {
//         const token = LocalTokenService.getToken().token;
//         const tRoles = tokenRoles(token);
//         return (
//             IsArrayWithLength(tRoles) &&
//             tRoles.find((r) => roles.includes(r)) !== undefined
//         );
//     } catch {
//         return false;
//     }
// };

export const getBooleanValueFromString = (value) => {
    switch (value) {
        case "Yes":
            return true;
        case "No":
            return false;
        default:
            return false;
    }
};

export const getContactStringName = (contactObject) => {
    if (!contactObject) return;
    return [
        contactObject.firstName,
        contactObject.middleInitial,
        contactObject.lastName,
    ].join(" ");
};

export const isObjectSameValues = (obj1, obj2) => {
    // The order in which you pass arguments to a function is significant in this case,
    // isObjectSameValues will take the keys from the first object and
    // compare their values with the corresponding values in the second object.

    // Check if both parameters are objects
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
        const bothFieldsEmpty =
            (!isNotNullOrUndefined(obj1) || isStringAndEmpty(obj1)) &&
            (!isNotNullOrUndefined(obj2) || isStringAndEmpty(obj2));
        return bothFieldsEmpty || obj1 === obj2;
    }

    if (isDayjs(obj1) && isDayjs(obj2)) {
        return obj1.isSame(obj2);
    }

    if (!isNotNullOrUndefined(obj1) || !isNotNullOrUndefined(obj2)) {
        return obj1 === obj2;
    }

    if (!isNotNullOrUndefined(obj1) || !isNotNullOrUndefined(obj2)) {
        return obj1 === obj2;
    }

    const entries1 = Object.entries(obj1);
    const entries2 = Object.entries(obj2);

    if (entries1.length > entries2.length) {
        return false;
    }

    return !entries1.some(([key, value]) => {
        // Recursively compare nested objects
        if (!(key in obj2) || !isObjectSameValues(value, obj2[key])) {
            return true;
        }
        return false;
    });
};

export const getSignatures = (fileType) => {
    switch(fileType) {
        case 'png':
            return "image/png";
        case 'jpeg':
            return "image/jpeg";
        case 'jpg':
            return "image/jpg";
        case 'pdf':
            return "application/pdf";
        default:
            return ""
    }
}

