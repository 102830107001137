import { AppRouters } from "../Routers";
import BlankLayout from "../../Components/Layout/BlankLayout";
import ErrorPage from "../../Components/Layout/ErrorPage";
import NoMatch from "../../Components/Layout/NoMatch";
import AuthRoute from "../../Components/AuthRoute";

export const AuthenticateRoute = {
    path: AppRouters.Root.path,
    element: (
        <AuthRoute>
            <BlankLayout />
        </AuthRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
        {
            path: AppRouters.Login.path,
            async lazy() {
                const { Login } = await import("../../Pages/Auth/Login");
                return { Component: Login };
            },
        },
        {
            path: AppRouters.ForgotPassword.path,
            async lazy() {
                const { ForgotPassword } = await import("../../Pages/Auth/ForgotPassword");
                return { Component: ForgotPassword };
            },
        },
        {
            path: AppRouters.ResetPassword.path,
            async lazy() {
                const { ResetPassword } = await import("../../Pages/Auth/ResetPassword");
                return { Component: ResetPassword };
            },
        },
        {
            path: "*",
            element: <NoMatch />,
            errorElement: <ErrorPage />,
        },
    ],
};
