import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../Components/Layout/MainLayout";
import ErrorPage from "../Components/Layout/ErrorPage";
import NoMatch from "../Components/Layout/NoMatch";
import { AppRouters } from "./Routers";
import PrivateRoute from "../Components/PrivateRoute";
import BlankLayout from "../Components/Layout/BlankLayout";
import { PatientRoute } from "./Routers/PatientRoute";
import { GroupRoute } from "./Routers/GroupRoute";
import { OrderRoute } from "./Routers/OrderRoute";
import { AuthenticateRoute } from "./Routers/AuthRoute";
import { ContactRoute } from "./Routers/ContactRoute";

const AppRouter = createBrowserRouter([
    {
        path: AppRouters.Root.path,
        element: (
            <PrivateRoute>
                <MainLayout />
            </PrivateRoute>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                async lazy() {
                    const { Dashboard } = await import("../Pages/Dashboard");
                    return { Component: Dashboard };
                },
            },
            {
                path: AppRouters.Any.path,
                element: <NoMatch />,
                errorElement: <ErrorPage />,
            },
        ],
    },
    { ...AuthenticateRoute },
    {
        path: AppRouters.Logout.path,
        element: <BlankLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                async lazy() {
                    const { Logout } = await import("../Pages/Auth/Logout");
                    return { Component: Logout };
                },
            },
        ],
    },
    { ...GroupRoute },
    { ...PatientRoute },
    ...OrderRoute,
    { ...ContactRoute },
    {
        path: AppRouters.Any.path,
        element: <NoMatch />,
        errorElement: <ErrorPage />,
    },
]);

export default AppRouter;
