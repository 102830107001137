import { useContext, createContext } from "react";
import { BaseProps } from "../Models/CommonModels";
import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";

interface INotificationContext {
    notifications: NotificationInstance;
    context: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const NotificationContext = createContext<INotificationContext>(
    {} as ReturnType<typeof useNotificationProvider>
);

const useNotificationProvider = () => {
    const [api, contextHolder] = notification.useNotification();
    return { notifications: api, context: contextHolder };
};

export const NotificationProvider: React.FC<BaseProps> = ({ children }) => {
    const values = useNotificationProvider();
    return (
        <NotificationContext.Provider value={values}>
            {values.context}
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext);
