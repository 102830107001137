import React from "react";
import { Avatar, Button, Tooltip } from "antd";
import { IUser } from "../../Models/CommonModels";
import { UserOutlined } from "@ant-design/icons";
import { AppRouters } from "../../Utilities/Routers";
import { useNavigate } from "react-router-dom";

type UserProfileComProps = { user: IUser | null; isExternal: boolean };
const UserProfileCom: React.FC<UserProfileComProps> = ({ user, isExternal }) => {
    const navigate = useNavigate();
    return (
        <div className="d-flex align-items-center">
            <Button type="text" className="d-flex align-items-center me-2 gap-2 pe-0 me-0">
                <div className="d-flex flex-column align-items-end text-uppercase">
                    <h6 className="user-profile mb-0">
                        {user?.firstName} {user?.lastName}
                    </h6>
                    <span className="user-sub">Operations {isExternal && "- External"}</span>
                </div>
                <Avatar className="avatar">
                    {user?.firstName.charAt(0).toUpperCase()}
                    {user?.lastName.charAt(0).toUpperCase()}
                </Avatar>
            </Button>
            {isExternal && (
                <Tooltip
                    placement="bottom"
                    title={"Log Out"}
                    color="white"
                    className=""
                    overlayInnerStyle={{ color: "black" }}
                >
                    <div className=" mx-2 text-white header-logo">
                        <UserOutlined
                            className="text-white px-2"
                            onClick={() => navigate(AppRouters.Logout.path)}
                            onPointerOverCapture={undefined}
                            onPointerMoveCapture={undefined}
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default UserProfileCom;
