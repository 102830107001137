export const REACT_QUERY_OFFLINE_CACHE = "REACT_QUERY_OFFLINE_CACHE";

export const QUERY_KEY_GLOBAL_DATA = "global-data";
export const QUERY_KEY_DASHBOARD_GROUP_INFO = "dashboard-group-info";
export const QUERY_KEY_DASHBOARD_PATIENT_INFO = "dashboard-patient-info";
export const QUERY_KEY_DASHBOARD_ORDER_INFO = "dashboard-order-info";
export const QUERY_KEY_DASHBOARD_GROUP_SEARCH = "dashboard-group-search";
export const QUERY_KEY_DASHBOARD_PATIENT_SEARCH = "dashboard-patient-search";
export const QUERY_KEY_DASHBOARD_ORDER_SEARCH = "dashboard-order-search";

export const QUERY_KEY_USERS = "users";
export const QUERY_KEY_COUNTRIES = "countries";
export const QUERY_KEY_GENDER_OPTIONS = "gender";
export const QUERY_KEY_PLAN_TYPE_OPTIONS = "plan-type-options";
export const QUERY_KEY_MARKUP_OPTIONS = "markup-options";
export const QUERY_KEY_AGGREGATOR_TYPE_OPTIONS = "aggregator-type-options";

export const QUERY_KEY_ELIGIBILITY_SOURCE_FOUND_IN_OPTIONS = "eligibility-source-found-in-options";
export const QUERY_KEY_ELIGIBILITY_TRANSFER_STATUS_OPTIONS = "eligibility-transfer-status-options";
export const QUERY_KEY_ELIGIBILITY_TRANSFER_TYPE_OPTIONS = "eligibility-transfer-type-options";
export const QUERY_KEY_ELIGIBILITY_LOAD_STATUS_OPTIONS = "eligibility-load-status-options";
export const QUERY_KEY_ELIGIBILITY_IMPORT_TECHNICAL_CONTACT =
    "eligibility-import-technical-contact";

export const QUERY_KEY_ELIGIBILITY = "eligibility";
export const QUERY_KEY_SUBSCRIBERS = "subscribers";
export const QUERY_KEY_SEARCH = "search";

export const QUERY_KEY_ADDRESS_TYPES = "address-types";
export const QUERY_KEY_PHONE_TYPES = "phone-types";
export const QUERY_KEY_EMAIL_TYPES = "email-types";
export const QUERY_KEY_AGGREGATORS = "aggregators";
export const QUERY_KEY_AGGREGATOR_TYPES = "aggregator-types";
export const QUERY_KEY_RELATIONSHIP_TYPES = "relationships";
export const QUERY_KEY_FORMULARY_STATUS = "formulary-status";
export const QUERY_KEY_GROUP_STATUS = "group-status";
export const QUERY_KEY_ADDITIONAL_NOTES = "additional-notes";
export const QUERY_KEY_MEDICATIONS = "medications";
export const QUERY_KEY_MASTER_DRUGS = "master-drugs";
export const QUERY_KEY_PHARMACIES = "pharmacies";

export const QUERY_KEY_CONTACT_INFORMATION = "contact-info";
export const QUERY_KEY_IDENTIFIER_MAPPING = "identifier-mapping";
export const QUERY_KEY_COMPANY_INFORMATION = "company-info";
export const QUERY_KEY_IDENTIFICATION = "identification";
export const QUERY_KEY_ELIGIBILITY_IMPORT = "eligiblity-import";
export const QUERY_KEY_FORMULARY_INFORMATION = "formulary";
export const QUERY_KEY_ADDRESSES = "addresses";
export const QUERY_KEY_EMAIL_ADDRESSES = "email-addresses";
export const QUERY_KEY_PHONE_NUMBERS = "phone-numbers";
export const QUERY_KEY_SUBSCRIBER_INFORMATION = "subscriber-info";
export const QUERY_KEY_PLAN_INFORMATION = "plan-info";

export const QUERY_KEY_CONTACTS = "contacts";

export const QUERY_KEY_ALLERGIES = "allergies";

export const QUERY_KEY_CONTACT_ROLES = "contact-roles";

// Groups
export const QUERY_KEY_GROUPS = "groups";

// Patients
export const QUERY_KEY_PATIENTS = "patients";
export const QUERY_KEY_PATIENT_SUBSCRIBER_INFO = "patients-subscriber-information";
export const QUERY_KEY_PATIENT_ORDERS_IN_PROGRESS = "patients-orders-in-progress";

// Orders
export const QUERY_KEY_ORDERS = "orders";
export const QUERY_KEY_ORDER_STATUS = "order-status";
export const QUERY_KEY_ORDER_SUMMARY = "order-summary";
export const QUERY_KEY_PRESCRIBERS_SEARCH = "prescribers-search";
export const QUERY_KEY_ORDER_NOTES = "order-notes";
export const QUERY_KEY_ORDERS_BULK_ASSIGN_PHARMACY = "orders-bulk-assign-pharmacy";
