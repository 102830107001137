import React from "react";
import { useLocation } from "react-router-dom";

const NoMatch = ()=>{
    let location = useLocation();
    return (
        <div>
            <h3>404, Page not found.</h3>
            <p>
                The requested URL <code>{location.pathname}</code> was not found.
            </p>
        </div>
    );
}
export default NoMatch;