import React from "react";
import { App as AntApp } from "antd";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import AppRouter from "./Utilities/BrowserRouter";
import AppQueryClient from "./Utilities/AppQueryClient";
import AppTheme from "./Utilities/AppTheme";
import { AuthProvider } from "./Hooks/useAuth";
import { NotificationProvider } from "./Hooks/useNotifications";

const App: React.FC = () => (
    <QueryClientProvider client={AppQueryClient}>
        <AuthProvider>
            <NotificationProvider>
                <ConfigProvider theme={AppTheme}>
                    <AntApp>
                        <RouterProvider router={AppRouter} />
                    </AntApp>
                </ConfigProvider>
            </NotificationProvider>
        </AuthProvider>
    </QueryClientProvider>
);

export default App;
