import { AppRouters } from "../Routers";
import MainLayout from "../../Components/Layout/MainLayout";
import ErrorPage from "../../Components/Layout/ErrorPage";
import PrivateRoute from "../../Components/PrivateRoute";
import { ElectRxExternalUsers, ElectRxUsers } from "../Constants/UserRoles";

export const OrderRouteWithExternal = {
    element: (
        <PrivateRoute roles={ElectRxUsers.concat(ElectRxExternalUsers)}>
            <MainLayout />
        </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
        {
            path: AppRouters.Order.PatientLookup.path,
            async lazy() {
                const { PatientLookupPage } = await import("../../Pages/Order/PatientLookupPage");
                return { Component: PatientLookupPage };
            },
        },
        {
            path: AppRouters.Order.PatientInformation.path,
            async lazy() {
                const { PatientInformationPage } = await import(
                    "../../Pages/Order/PatientInformationPage"
                );
                return { Component: PatientInformationPage };
            },
        },
        {
            path: AppRouters.Order.PatientInformationNoOrder.path,
            async lazy() {
                const { PatientInformationPage } = await import(
                    "../../Pages/Order/PatientInformationPage"
                );
                return { Component: PatientInformationPage };
            },
        },
        {
            path: AppRouters.Order.Prescriber.path,
            async lazy() {
                const { PrescriberPage } = await import("../../Pages/Order/PrescriberPage");
                return { Component: PrescriberPage };
            },
        },
        {
            path: AppRouters.Order.NewPrescriber.path,
            async lazy() {
                const { NewPrescriberPage } = await import("../../Pages/Order/NewPrescriberPage");
                return { Component: NewPrescriberPage };
            },
        },
        {
            path: AppRouters.Order.Medication.path,
            async lazy() {
                const { MedicationPage } = await import("../../Pages/Order/MedicationPage");
                return { Component: MedicationPage };
            },
        },
    ],
};

export const OrderRoutePrivate = {
    element: (
        <PrivateRoute>
            <MainLayout />
        </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
        {
            path: AppRouters.Order.Summary.path,
            async lazy() {
                const { OrderSummaryPage } = await import("../../Pages/Order/SummaryPage");
                return { Component: OrderSummaryPage };
            },
        },
    ],
};

export const OrderRoute = [OrderRoutePrivate, OrderRouteWithExternal];
